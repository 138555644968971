import React, { useEffect, useState } from "react"
import Layout from "../../components/shared-layout";
import { Alert, Box, Checkbox, CircularProgress, Divider, FormControlLabel, Grow, IconButton, Skeleton, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import SnowMountain from "../images/snow-mountain.jpg";
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VmButton from "../../components/shared-button";
import useStores from "../../hooks/use-stores";
import { navigate } from "gatsby";
import { FilterType, Language, ListLayoutType, OrderStatus, PaymentType, ProfileSectionType, SearchCategory } from "../../constants/options";
import SlideDown from "react-slidedown";
import 'react-slidedown/lib/slidedown.css';
import ProductCard from "../../components/product-card";
import ListAltIcon from '@mui/icons-material/ListAlt';
import { isWeb } from "../../utilities/platform";
import { CHECK_OUT_ID_KEYWORD, CHECK_OUT_PAYMENT_METHOD_KEYWORD } from "../../constants/settings";
import { IsAuthed } from "../../utilities/general";
import * as queryString from "query-string";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SellIcon from '@mui/icons-material/Sell';
import CancelIcon from '@mui/icons-material/Cancel';
import { GetCartItemNumAsGuest, RemoveItemFromCartAsGuest } from "../../utilities/cart-item";
import CheckOutStepper from "../../components/checkout-stepper";

const OrderConfirmationPage = observer(({ location }: { location: any }) => {
  const { t }: any = useTranslation();
  const { rootStore, bookingStore } = useStores();
  const [orderStatus, setOrderStatus] = useState<OrderStatus>(OrderStatus.NOTIFICATION);
  const { id, paymentMethod, transactionId, token, status, PayerID } = queryString.parse(location.search);
  const [summary, setSummary] = useState<any>();

  useEffect(() => {
    console.log(location);
    setOrderStatus(status == OrderStatus.SUCCESS ? OrderStatus.SUCCESS
      : status == OrderStatus.CANCEL ? OrderStatus.CANCEL : OrderStatus.FAIL);
    if (isWeb && location.search.includes(CHECK_OUT_ID_KEYWORD) && location.search.includes(CHECK_OUT_PAYMENT_METHOD_KEYWORD)) {
      if (status == OrderStatus.SUCCESS) {
        let req: any = { orderId: id, customerId: IsAuthed() ? JSON.parse(localStorage.USER_INFO).id : 0 };
        switch (paymentMethod) {
          case PaymentType.CREDIT_CARD: req.squareTransactionId = transactionId;
            break;
          case PaymentType.POLI: req.poliToken = token;
            break;
          case PaymentType.PAYPAL: req.payPalReferenceId = token;
            break;
        }
        bookingStore.placeOrder(req)
          .catch(() => setOrderStatus(OrderStatus.FAIL))
          .then(() => {
            if (!IsAuthed()) {
              localStorage.CART_LIST = JSON.stringify({ productList: [], totalPrice: 0 });
              GetCartItemNumAsGuest();
            }
            delete localStorage.TP_BOOKING;
            delete localStorage.TRIP_PLAN;
          });
        bookingStore.getBookingDetailByOrderStr(id as string)
          .then((res) => setSummary(res));
        console.log(req);
      }
    } else navigate('/');
  }, []);

  return (
    <Layout pageName={t('ORDER_CONFIRMATION')}
      breadCrumb={[
        { to: "/", label: t('HOME') },
        { to: "/cart", label: t('SHOPPING_CART') },
        { to: "", label: t('CONFIRMATION') }
      ]}>
      <Box className={PADDING_HORIZONTAL}>
        <CheckOutStepper activeStep={2} />
        <br />
        {bookingStore.loading ? <>
          <Box className="fixed top-0 left-0 right-0 bottom-0 z-50" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
            <Box className="absolute top-1/2 w-full text-center translate-y-neg-1/2">
              <CircularProgress />
              <p className="text-white text-2xl 2xl:text-3xl mt-4">{t('PROCESSING_PAYMENT')}...</p>
              <p className="text-white text-2xl 2xl:text-3xl mt-2">{t('PLEASE_DONT_LEAVE_THE_PAGE')}</p>
            </Box>
          </Box>
          <Skeleton variant="rectangular" width="100%" height={100} />
          <Skeleton variant="rectangular" width="100%" height={150} className="mt-6 mb-10" />
        </> : <>
          <Box className="border bg-white shadow-lg text-center py-4 mb-4">
            {orderStatus == OrderStatus.SUCCESS &&
              <Box sx={ITEM_PERFECT_INLINED} className="justify-center mb-4">
                <CheckCircleOutlineIcon />
                <p className="font-light ml-2">{t('THANK_YOU_FOR_UR_ORDER_FROM_TOUR61')}</p>
              </Box>}

            {orderStatus == OrderStatus.SUCCESS ? <>
              <p className="font-bold text-xl sm:text-2xl 2xl:text-3xl mb-4 uppercase">
                {t('YOUR_ORDER_NUM_IS')} <span className="text-sky-400">{summary && summary.orderId}</span>
              </p>
              <p className="font-light">{t('YOUR_VOUCHER_WILL_BE_SENT_VIA_EMAIL')}</p>
            </> : <>
              <Box sx={ITEM_PERFECT_INLINED} className="text-red-500 mb-4 justify-center uppercase">
                <CancelIcon color="inherit" fontSize="large" />
                <p className="font-bold text-2xl 2xl:text-3xl ml-2">
                  {orderStatus == OrderStatus.CANCEL ? t('PAYMENT_CANCELLED') : t('PAYMENT_FAILED')}
                </p>
              </Box>
              <p className="font-light">{t('THIS_PAYMENT_WAS')} {orderStatus == OrderStatus.CANCEL ? t('CANCELLED') : t('FAILED')}. {t('U_CAN_GO_TO_CART_AND_MAKING_PAYMENT_AGAIN')}</p>
            </>}
          </Box>

          {bookingStore.loadingSummaryList ? <Skeleton variant="rectangular" width="100%" height={100} />
            : <Box className="bg-white border shadow-lg p-4 mt-4">
              <Box sx={ITEM_PERFECT_INLINED}>
                <SellIcon />
                <p className="ml-2">{t('SUMMARY')}</p>
              </Box>
              {summary && <>
                <table className="border border-t-0 w-full mt-4">
                  <tbody>
                    {summary.products.map((p: any, i: number) => (
                      <>
                        <tr key={`summary_product_${i}`} className="w-1/3 border-t border-b">
                          <td colSpan={2} className="p-2">{p.productName}</td>
                        </tr>
                        <tr className="w-2/3">
                          <td className="font-light p-2">{t('PRODUCT_DETAIL')}</td>
                          <td className="border-l font-light p-2">
                            <p>{t('TRAVEL_DATE')}: {p.startDate.split('T')[0]}</p>
                            <p>{t('QUANTITY')}: {p.quantityStr}</p>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
                {summary.deductAmount > 0 && <p className="text-center 2xl:text-lg mt-2">{t('DISCOUNT')}: -${summary.deductAmount}</p>}
                <p className="text-center text-xl 2xl:text-2xl mt-2 mb-8">{t('TOTAL')}: <span className="text-sky-400">${summary.totalCost.toFixed(2)}</span></p>

                {IsAuthed() && <Box className="flex justify-center">
                  <VmButton onClick={orderStatus == OrderStatus.SUCCESS ? () => navigate('/user-profile', { state: { tab: ProfileSectionType.BOOKINGS } }) : () => navigate('/cart/check-out')}>
                    {orderStatus == OrderStatus.SUCCESS ? t('MANAGE_MY_BOOKINGS') : t('CHECK_OUT_AGAIN')}
                  </VmButton>
                </Box>}
              </>}
            </Box>}
        </>}
      </Box>
    </Layout>
  )
});

export default OrderConfirmationPage;
